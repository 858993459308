<template>
  <div
    class="c-app flex-row align-items-center gradient"
    :class="{
      'c-dark-theme': $store.getters.darkMode,
      '_light': !$store.getters.darkMode
    }"
  >
    <CContainer >
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: "Auth",
  components: {
  }
}
</script>

<style lang="scss" scoped>
.gradient {
  background: radial-gradient(46.85% 132.47% at 64.58% 62.5%, #1A1E22 0%, rgba(26, 30, 34, 0) 100%), radial-gradient(50% 120.5% at 21.08% 23.62%, #3686F6 0%, rgba(32, 168, 216, 0) 100%), #2E3539;
  &._light {
    background: radial-gradient(46.85% 132.47% at 64.58% 62.5%, #e1e1e1 0%, rgba(26, 30, 34, 0) 100%), radial-gradient(50% 120.5% at 21.08% 23.62%, rgba(32, 168, 216, 0.56) 0%, rgba(32, 168, 216, 0) 100%), #f4fbff;;
  }
}

</style>
