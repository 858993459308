var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "c-app flex-row align-items-center gradient",
      class: {
        "c-dark-theme": _vm.$store.getters.darkMode,
        _light: !_vm.$store.getters.darkMode,
      },
    },
    [
      _c(
        "CContainer",
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }